import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CheckoutEventsService } from 'mycs/checkout/services/CheckoutEventsService';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { ToastMsg, useToast } from 'mycs/hooks/useToast';
import { useCart } from 'mycs/hooks/useCart';
import { useCartTools } from 'mycs/hooks/useCartTools';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import Logger from 'mycs/shared/services/Logger';

import styles from './AddToCartButton.scss';

interface Props {
  product: any;
  className?: string;
  quantity?: number;
  text?: string;
  includeAssemblyPrice?: boolean;
  hasBaseboardCuts?: boolean;
  baseboardCuts?: { depth: number; height: number };
  mobileIconOnly?: boolean;
  primaryLayout?: boolean;
  primaryLeftLayout?: boolean;
  isFullWidth?: boolean;
  transparentLayout?: boolean;
  thirdLevelCta?: boolean;
  isConfigurator?: boolean;
  isRightBarButton?: boolean;
  isSplitButtonRight?: boolean;
  hasIcon?: boolean;
}
/**
 * AddToCartButton
 */
export default function AddToCartButton({
  product,
  className = '',
  quantity = 1,
  text = 'Add to cart',
  mobileIconOnly = true,
  primaryLayout = false,
  primaryLeftLayout = false,
  isFullWidth = false,
  transparentLayout = false,
  thirdLevelCta = false,
  isConfigurator = false,
  isRightBarButton = false,
  isSplitButtonRight = false,
  hasBaseboardCuts = false,
  baseboardCuts = undefined,
  hasIcon = false,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { locale, countryCode } = useLocale();
  const device = useDevice();
  const { setNotification } = useToast();

  const { cart, setCart } = useCart();
  const { addLineItem } = useCartTools(cart, setCart);

  /**
   * Add current item to cart
   */
  const addToCart = async () => {
    let { uuid, structure, furniture_type } = product;
    let design;

    if (isConfigurator) {
      design = await DesignApiService.postDesign({
        furniture_type,
        structure,
        user_created: true,
      });
      uuid = design.uuid;
    }

    if (!uuid) {
      throw Error('invalid product uuid');
    }

    const updatedCart = await addLineItem(
      uuid,
      quantity,
      hasBaseboardCuts ? baseboardCuts : undefined
    );

    CheckoutEventsService.sendAddToCartEvent(
      updatedCart,
      (updatedCart?.lineItems || []).length - 1,
      quantity,
      locale,
      countryCode
    );

    navigate(RelativeUrlService.getCartUrl(locale));

    return;
  };

  /**
   * Handle click event
   */
  const handleClick = async () => {
    setIsLoading(true);

    try {
      await addToCart();
    } catch (error) {
      setIsLoading(false);
      setNotification(ToastMsg.TryAgain);
      Logger.error(`Could not add item to cart: ${error}`);
    }
  };

  const isIconButton =
    device.hasPhoneDimensions && mobileIconOnly && !primaryLeftLayout;
  const buttonClasses = classNames(styles.button, className, {
    [styles.iconButton]: isIconButton, // FIXME: could be done through CSS
  });

  const textButton = (
    <Button
      className={buttonClasses}
      isPrimaryCtaFallback={
        !primaryLayout && !primaryLeftLayout && !transparentLayout
      }
      isPrimaryCta={primaryLayout || primaryLeftLayout}
      isThirdLevelPrimaryCta={transparentLayout}
      isThirdLevelCta={thirdLevelCta}
      isFullWidth={isFullWidth || primaryLeftLayout}
      isInlineLeft={primaryLeftLayout}
      iconName={
        primaryLeftLayout || hasIcon ? 'configurator/add-to-cart' : undefined
      }
      iconAfter={primaryLeftLayout}
      itemScope
      itemType="http://schema.org/BuyAction"
      isLoading={isLoading}
      onClick={handleClick}
      text={text}
      isInline={hasIcon}
    />
  );

  const iconButton = (
    <Button
      className={buttonClasses}
      iconName="configurator/add-to-cart"
      iconSmall={!transparentLayout}
      isBigPrimarySquare={!transparentLayout}
      isPrimaryCtaFallback={!transparentLayout}
      isSmallSquare={transparentLayout}
      itemScope
      itemType="http://schema.org/BuyAction"
      isLoading={isLoading}
      onClick={handleClick}
    />
  );

  // TODO (Valentyn) Should be merged with the one above.
  const rightBarButton = (
    <Button
      isLoading={isLoading}
      isBigPrimarySquare
      isDropShadow
      text={text}
      itemScope
      itemType="http://schema.org/BuyAction"
      iconName="configurator/add-to-cart"
      onClick={handleClick}
    />
  );

  // Split Button from My designs page
  const splitButton = (
    <Button
      isLoading={isLoading}
      iconName="configurator/add-to-cart"
      onClick={handleClick}
      isSplitButtonRight
    />
  );

  return isRightBarButton
    ? rightBarButton
    : isSplitButtonRight
      ? splitButton
      : isIconButton
        ? iconButton
        : textButton;
}
